import axios from "axios";

//DEPLOY
const baseURL = "https://api.flight-times-gti.com/api/v1"; //PROD
//const baseURL = "https://uatapi.flight-times-gti.com/api/v1"; //UAT
//const baseURL = "https://api.dev-raiden.com/api/v1"; //DEV
//const baseURL = "http://localhost:3000/api/v1"; //DEV LOCAL

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(undefined, (error) => {
  if (error.response) {
    let message;

    if (error.response.data) {
      message = error.response.data;
    } else {
      message = error.response.statusText;
    }

    return {
      message,
      status: error.response.status,
    };
  } else {
    return { message: "Network error", status: 500 };
  }
});

export default instance;
